import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledWrap = styled(Box)({
    margin: "15px"
});

type Props = {
    children: JSX.Element;
};

const MainContentWrap = ({ children }: Props) => {
    return (
        <StyledWrap>
            {children}
        </StyledWrap>
    );
};

export default MainContentWrap;
