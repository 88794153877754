import { makeAutoObservable } from "mobx";
import { Organisations } from "../types/organisation";
import { getUKList } from "../api-actions/api-actions";
import throttle from "lodash.throttle";

class UKStore {
    data?: Organisations;
    ukSearch: string = "";

    constructor() {
        makeAutoObservable(this);
    }

    setData(data: Organisations) {
        this.data = data;
    }

    setSearch(str: string) {
        this.ukSearch = str;
    }

    getData() {
        const getUKListInfo = async () => {
            return await getUKList();
        };
        const throttledGetUKList = throttle(getUKListInfo, 100);
        throttledGetUKList()?.then((res) => {
            if (res) {
                this.setData(res);
            }
        });
    }
}

export const $ukStore = new UKStore();
