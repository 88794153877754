import {
    Grid,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
} from '@mui/material';
import {
    AddButton,
    SearchField,
} from '@synapse/frontend-react';
import { observer } from 'mobx-react';
import {
    ChangeEvent,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { addContractUser } from '../../../../../api-actions/ContractsApi';
import { $anchorRefStore } from '../../../../../store/AnchorRef';
import { $contractsDataStore } from '../../../../../store/ContractsStore';
import { $loginUserStore } from '../../../../../store/ResponseData';
import { contractMember } from '../../../../../types/contractMember';
import { DEFAULT_CONTRACT_MEMBER } from '../../../../../utils/const';
import ConfirmDialog from '../../../../ui/dialog/ConfirmDialog';
import AddUser from './AddUser';
import ContractCardMembersTableLine from './ContractCardMembersTableLine';

const ContractCardMembers = observer(() => {
    const {
        contractMembersPage,
        contractMembersRowsPerPage,
        contractMembersSort,
        contractDetail,
        contractMembersSearch,
    } = $contractsDataStore;

    useEffect(() => {
        $contractsDataStore.getMembersList(
            String(contractDetail?.guid),
            String(contractMembersPage + 1),
            contractMembersSort.sort.by,
            contractMembersSort.sort.order,
            String(contractMembersRowsPerPage),
            contractMembersSearch
        );
    }, [
        contractMembersPage,
        contractMembersRowsPerPage,
        contractMembersSort.sort.by,
        contractMembersSort.sort.order,
        contractDetail?.guid,
        contractMembersSearch,
    ]);

    const tableColumns = [
        {
            key: "id",
            label: "№ п/п",
            width: 100,
            hideSortIcon: true,
            show: true,
        },
        {
            key: "name",
            label: "ФИО сотрудника",
            width: 300,
            hideSortIcon: true,
            show: true,
        },
        // {
        //     key: "address",
        //     label: "Закрепление за адресом",
        //     width: 150,
        //     hideSortIcon: true,
        //     show: true,
        // },
        // {
        //     key: "startDate",
        //     label: "Дата трудоустройства",
        //     width: 200,
        //     hideSortIcon: true,
        //     show: true,
        // },
        // {
        //     key: "endDate",
        //     label: "Дата увольнения",
        //     width: 200,
        //     hideSortIcon: true,
        //     show: true,
        // },
        {
            key: "status",
            label: "Статус",
            width: 150,
            hideSortIcon: true,
            show: true,
        },
        {
            key: "action",
            label: "Действия",
            width: 100,
            hideSortIcon: true,
            show: $loginUserStore.isAdmin(),
        },
    ];
    const changeTableSort = useCallback(
        (key: string) => {
            if (key !== "status" && key !== "action") {
                $contractsDataStore.setMembersSort({
                    sort: {
                        by: key,
                        order:
                            contractMembersSort.sort.order === "asc"
                                ? "desc"
                                : "asc",
                    },
                });
            }
        },
        [contractMembersSort]
    );

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => {
        $contractsDataStore.setMembersPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        $contractsDataStore.setMembersRowsPerPage(
            parseInt(event.target.value, 10)
        );
        $contractsDataStore.setMembersPage(0);
    };

    const handleAddUser = () => {
        $contractsDataStore.setAddNewUser(true);
    };

    const handleChangeStatus = async (form: contractMember, status: string) => {
        setLoading(true);
        (form as any).status = status;
        $contractsDataStore.setLoading(true);
        await addContractUser(
            form
        ).then((contract) => {
            $contractsDataStore.setMemberEdit(false);
            if ($loginUserStore.data) {
                $contractsDataStore.getMembersList(
                    String(contractDetail?.guid),
                    String(contractMembersPage + 1),
                    contractMembersSort.sort.by,
                    contractMembersSort.sort.order,
                    String(contractMembersRowsPerPage),
                    contractMembersSearch
                );
                $contractsDataStore.setAddNewUser(false);
            }
        });
        $contractsDataStore.setMembersLoading(false);
        setLoading(false);
    };

    const [loading, setLoading] = useState<boolean>(false);

    return (<>
        <Grid container justifyContent={$loginUserStore.isAdmin() ? 'space-between' : 'flex-end'}>
            {
                $loginUserStore.isAdmin()
                    ? <AddButton
                        onClick={handleAddUser}
                        disabled={contractDetail?.available_users?.items.length === undefined}
                        style={{
                            margin: 0,
                        }}
                    />
                    : null
            }
            <SearchField
                value={contractMembersSearch}
                callback={(value) => $contractsDataStore.setMembersSearch(value)}
                callbackTimeout={700}
                style={{
                    width: 300
                }}
            />
        </Grid>
        <TableContainer
            component={Paper}
            style={{
                border: "1px solid #5ac8fa",
                margin: '15px 0 0',
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        {tableColumns.map((item: any) => {
                            if (!item.show) {
                                return <></>;
                            }
                            return (
                                <TableCell
                                    key={item.key}
                                    id={item.key}
                                    sortDirection={
                                        contractMembersSort.sort.by === item.key
                                            ? contractMembersSort.sort.order
                                            : false
                                    }
                                    style={{
                                        width: item.width,
                                        maxWidth: item.width,
                                    }}
                                >
                                    <TableSortLabel
                                        active={
                                            contractMembersSort.sort.by ===
                                            item.key
                                        }
                                        hideSortIcon={item.hideSortIcon}
                                        direction={
                                            contractMembersSort.sort.by ===
                                            item.key
                                                ? contractMembersSort.sort.order
                                                : "asc"
                                        }
                                        onClick={() => {
                                            if (!item.hideSortIcon) {
                                                changeTableSort(item.key);
                                            }
                                        }}
                                    >
                                        {item.label}
                                    </TableSortLabel>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <ContractCardMembersTableLine />
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={
                    $contractsDataStore.contractMembersList?.pagination.total! ?? 0
                }
                page={contractMembersPage}
                onPageChange={handleChangePage}
                rowsPerPage={contractMembersRowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                style={{ display: "flex" }}
            />
        </TableContainer>
        <Menu
            disableScrollLock={true}
            anchorEl={$anchorRefStore.data}
            //keepMounted
            open={Boolean($anchorRefStore.data)}
            onClose={() => {
                $anchorRefStore.setAnchorRef(null);
                $contractsDataStore.setMemberEditData(DEFAULT_CONTRACT_MEMBER);
            }}
        >
            <MenuItem
                onClick={(event) => {
                    $anchorRefStore.setAnchorRef(null);
                    $contractsDataStore.setMemberEdit(true);
                }}
            >{
                $contractsDataStore?.memberEditData.status === 'WORK'
                    ? 'Архивировать'
                    : 'Вернуть из архива'
            }</MenuItem>
        </Menu>
        <ConfirmDialog
            display={$contractsDataStore.memberEdit}
            title={
                $contractsDataStore?.memberEditData.status === 'WORK'
                    ? 'Архивировать сотрудника'
                    : 'Вернуть из архива'
            }
            submitText={
                $contractsDataStore?.memberEditData.status === 'WORK'
                    ? 'Архивировать'
                    : 'Вернуть'
            }
            onClose={(event) => $contractsDataStore.setMemberEdit(false)}
            onSubmit={(event) => {
                handleChangeStatus(
                    $contractsDataStore?.memberEditData,
                    $contractsDataStore?.memberEditData.status === 'WORK'
                        ? 'EXCLUDED'
                        : 'WORK'
                );
            }}
            loading={loading}
        >
            <Typography variant="subtitle1">
                Вы уверены, что хотите{" "}
                {$contractsDataStore?.memberEditData.status === 'WORK'
                    ? 'отправить в архив'
                    : 'вернуть из архива'}
                {" "}сотрудника{" "}
                <b>{$contractsDataStore?.memberEditData?.name}</b>{" "}
                для текущего договора?
            </Typography>
        </ConfirmDialog>
        {$contractsDataStore.addNewUser && <AddUser />}
    </>);
});
export default ContractCardMembers;
