import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import { SearchField } from '@synapse/frontend-react';
import { observer } from 'mobx-react';
import {
    ChangeEvent,
    useCallback,
    useEffect,
} from 'react';
import { StyledCard } from '../../../App';
import AddContract from './AddContract';
import ContractTableLine from './ContractTableLine';
import { CustomCircularProgress } from '../../ui/CustomCircularProgress';
import { $anchorRefStore } from '../../../store/AnchorRef';
import { $contractsDataStore } from '../../../store/ContractsStore';
import { $loginUserStore } from '../../../store/ResponseData';
import MainContentWrap from '../../ui/wrappers/MainContentWrap';

const Contracts = observer(() => {
    const {
        contractsPage,
        contractsRowsPerPage,
        contractsloadingTable,
        contractsSort,
        contractsSearch,
    } = $contractsDataStore;

    useEffect(() => {
        $contractsDataStore.getData(
            String(contractsPage + 1),
            contractsSort.sort.by,
            contractsSort.sort.order,
            String(contractsRowsPerPage),
            contractsSearch
        );
    }, [
        contractsSearch,
        contractsPage,
        contractsRowsPerPage,
        contractsSort,
    ]);

    const tableColumns = [
        {
            key: "from",
            label: "Управляющая компания",
            width: 150,
            hideSortIcon: false,
        },
        {
            key: "to",
            label: "Организация",
            width: 150,
            hideSortIcon: false,
        },
        {
            key: "number",
            label: "№ договора",
            width: 150,
            hideSortIcon: false,
        },
        {
            key: "start",
            label: "Начало действия",
            width: 121,
            hideSortIcon: true,
        },
        {
            key: "end",
            label: "Окончание действия",
            width: 150,
            hideSortIcon: true,
        },
        {
            key: "description",
            label: "Описание",
            width: 150,
            hideSortIcon: true,
        },
        {
            key: "status",
            label: "Статус",
            width: 100,
            hideSortIcon: true,
        },
        {
            key: "action",
            label: "Действия",
            width: 115,
            hideSortIcon: true,
        }
    ];

    const changeTableSort = useCallback(
        (key: string) => {
            $contractsDataStore.setSort({
                sort: {
                    by: key,
                    order:
                        contractsSort.sort.by !== key
                            ? 'asc'
                            : contractsSort.sort.order === 'asc' ? 'desc' : 'asc',
                },
            });
        },
        [contractsSort]
    );

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => {
        $contractsDataStore.setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        $contractsDataStore.setRowsPerPage(parseInt(event.target.value, 10));
        $contractsDataStore.setPage(0);
    };

    return (
        <MainContentWrap>
            <>
                <Grid
                    container
                    direction={"row"}
                    justifyContent={"space-between"}
                    style={{
                        paddingBottom: "1em",
                        paddingLeft: ".75em"
                    }}
                >
                    <Grid item>
                        <Grid container direction={"row"} spacing={2} alignItems={"baseline"}>
                            <Grid item>
                                <h3>Подрядчики и договоры</h3>
                            </Grid>
                            {$loginUserStore.isAdmin() ? (
                                <Grid item>
                                    <IconButton
                                        onClick={() =>
                                            $contractsDataStore.setOpenEditContract(true)
                                        }
                                    >
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                </Grid>
                            ) : null}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <SearchField
                            value={contractsSearch}
                            callback={(value) => $contractsDataStore.setSearch(value)}
                            callbackTimeout={700}
                            style={{
                                width: 300
                            }}
                        />
                    </Grid>
                </Grid>
                <StyledCard>
                    <TableContainer
                        component={Paper}
                        style={{
                            border: "1px solid #5ac8fa",
                            margin: 0
                            // maxHeight: 875,
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {tableColumns.map((item: any) => {
                                        return (
                                            <TableCell
                                                key={item.key}
                                                id={item.key}
                                                sortDirection={
                                                    contractsSort.sort.by === item.key
                                                        ? contractsSort.sort.order
                                                        : false
                                                }
                                                style={{
                                                    width: item.width,
                                                    maxWidth: item.width
                                                }}
                                            >
                                                <TableSortLabel
                                                    active={contractsSort.sort.by === item.key}
                                                    hideSortIcon={item.hideSortIcon}
                                                    direction={
                                                        contractsSort.sort.by === item.key
                                                            ? contractsSort.sort.order
                                                            : "asc"
                                                    }
                                                    onClick={() => {
                                                        if (!item.hideSortIcon) {
                                                            changeTableSort(item.key);
                                                        }
                                                    }}
                                                >
                                                    {item.label}
                                                </TableSortLabel>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {contractsloadingTable ? (
                                    <TableRow>
                                        <TableCell colSpan={8}>
                                            <CustomCircularProgress />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <ContractTableLine />
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={$contractsDataStore.contractsData?.pagination?.total! ?? 0}
                            page={contractsPage}
                            onPageChange={handleChangePage}
                            rowsPerPage={contractsRowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            style={{ display: "flex" }}
                        />
                    </TableContainer>
                    {!$loginUserStore.isAdmin() ? null : (
                        <Menu
                            disableScrollLock={true}
                            anchorEl={$anchorRefStore.data}
                            keepMounted
                            open={Boolean($anchorRefStore.data)}
                            onClose={() => {
                                $anchorRefStore.setAnchorRef(null);
                            }}
                        >
                            {
                                <MenuItem
                                    onClick={(event) => {
                                        $anchorRefStore.setAnchorRef(null);
                                        $contractsDataStore.setOpenEditContract(true);
                                    }}
                                >
                                    Редактировать
                                </MenuItem>
                            }
                        </Menu>
                    )}

                    {$contractsDataStore.openEditContract && <AddContract />}
                </StyledCard>
            </>
        </MainContentWrap>
    );
});
export default Contracts;
