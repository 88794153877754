import axios from 'axios';
import Notification from './components/ui/Notification';
import { $loginUserStore } from './store/ResponseData';

const controller = new AbortController();

axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
};

const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL ?? 'https://geo-api.my-dom.tech/',
    responseType: 'json',
    signal: controller.signal,
});

http.interceptors.request.use(function (config) {
    if ($loginUserStore.getToken() !== undefined) {
        config.headers.Authorization = $loginUserStore.getToken();
    }

    return config;
}, function (error) {
    return Promise.reject(error);
});

http.interceptors.response.use(function (response) {
    if (response.data.body === 'error') {
        const status = parseInt(response.data.error.status_code);

        if (status === 401) {
            Notification({
                title: 'Ошибка авторизации',
                type: 'error',
            });
            $loginUserStore.logout();
        } else if (status === 500) {
            Notification({
                title: 'Ошибка: ' + response.data.error.message,
                type: 'error',
            });
            return response;
        }
    }

    return response;
}, function (error) {
    return Promise.reject(error);
});

export default http;
