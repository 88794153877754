import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Tooltip,
} from '@mui/material';
import Box from '@mui/material/Box';
import {
    ExportButton,
    SearchField,
} from '@synapse/frontend-react';
import { observer } from 'mobx-react';
import {
    ChangeEvent,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { StyledCard } from '../../../App';
import { CustomCircularProgress } from '../../ui/CustomCircularProgress';
import AddDevice from './AddDevice';
import Attach from './Attach';
import ColumnSelectBtn from './ColumnSelectBtn';
import DeviceTableLine from './DeviceTableLine';
import { $anchorRefStore } from '../../../store/AnchorRef';
import { $deviceTableDataStore } from '../../../store/DeviceTableStore';
import { $membersDataStore } from '../../../store/MembersStore';
import { $loginUserStore } from '../../../store/ResponseData';
import MainContentWrap from '../../ui/wrappers/MainContentWrap';
import { exportDevices } from '../../../api-actions/DevicesApi';

const DevicesList = observer(() => {
    const [exportLoading, setExportLoading] = useState(false);
    const {
        loadingDeviceTable,
        devicePage,
        deviceRowsPerPage,
        deviceSort,
        deviceSearch,
        tableColumns
    } = $deviceTableDataStore;

    const {administrator, ukWorker, poWorker, headRegion} = $loginUserStore
    
    const tableColumnsInit = [
        {
            key: "imei",
            label: "Идентификатор",
            width: 170,
            hideSortIcon: false,
            show: true,
        },
        {
            key: "model",
            label: "Модель",
            width: 170,
            hideSortIcon: false,
            show: true,
        },
        {
            key: "phone",
            label: "Номер телефона",
            width: 170,
            hideSortIcon: false,
            show: true,
        },
        {
            key: "name",
            label: "Название",
            width: 170,
            hideSortIcon: false,
            show: true,
        },
        {
            key: "description",
            label: "Описание",
            width: 170,
            hideSortIcon: false,
            show: true,
        },
        {
            key: "status_date",
            label: "Статус",
            width: 200,
            hideSortIcon: false,
            show: true,
        },
        {
            key: "last_online",
            label: "Координаты",
            width: 200,
            hideSortIcon: false,
            show: true,
        },
        {
            key: "attach",
            label: "Сотрудник",
            width: '100%',
            hideSortIcon: true,
            show: true,
        },
        {
            key: "battery",
            label: "Заряд",
            width: '100%',
            show: true,
        },
        {
            key: "action",
            label: "Действия",
            width: 115,
            hideSortIcon: true,
            show: true,
        },
    ];    

    useEffect(() => {
        let tableColumns = tableColumnsInit;

        // убираем колонки, которые предназначены только для админа
        if(!administrator) {
            tableColumns = tableColumns.filter(column => !['phone'].includes(column.key));           
        } 

        // Если это еще и не сотрудник УК или ПО - убираем еще и колонку действия
        if(!administrator && !ukWorker && !poWorker && !headRegion) {
            tableColumns = tableColumns.filter(column => !['action'].includes(column.key));
        } 

        $deviceTableDataStore.setTableColumns(tableColumns)
    }, []);

    useEffect(() => {
        $deviceTableDataStore.updateData();
    }, [
        devicePage,
        deviceRowsPerPage,
        deviceSort,
        deviceSearch,
    ]);

    const changeTableSort = useCallback(
        (key: string) => {
            $deviceTableDataStore.setSort({
                sort: {
                    by: key,
                    order:
                        deviceSort.sort.by !== key
                            ? 'asc'
                            : deviceSort.sort.order === 'asc' ? 'desc' : 'asc',
                },
            });
        },
        [deviceSort]
    );

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => {
        $deviceTableDataStore.setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        $deviceTableDataStore.setRowsPerPage(parseInt(event.target.value, 10));
        $deviceTableDataStore.setPage(0);
    };

    const exportTable = () => {
        setExportLoading(true);
        exportDevices().then((response) => {
            const data = response?.data?.data;

            require('downloadjs')(atob(data.content), data.name, data.content_type);
            setExportLoading(false);
        }).catch(() => setExportLoading(false));
    };

    return (
        <MainContentWrap>
            <>
                <Grid
                    container
                    direction={"row"}
                    justifyContent={"space-between"}
                    style={{
                        paddingBottom: "1em",
                        paddingLeft: ".75em"
                    }}
                >
                    <Grid item>
                        <Grid container direction={"row"} spacing={2} alignItems={"baseline"}>
                            <Grid item>
                                <h3>Устройства</h3>
                            </Grid>
                            {$loginUserStore.isAdmin() ? (
                                <Grid item>
                                    <IconButton
                                        onClick={() => $deviceTableDataStore.setAddNewDevice(true)}
                                    >
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                </Grid>
                            ) : null}
                            <Grid item>
                                <ColumnSelectBtn />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container flexDirection="row">
                            <Grid item style={{ marginRight: 15 }}>
                                <Tooltip title="Выгрузить все устройства в Excel" arrow>
                                    <Box sx={{
                                        height: {
                                            xs: 50,
                                            md: 40,
                                        },
                                        display: "block"
                                    }}>
                                        <ExportButton
                                            onClick={exportTable}
                                            loading={exportLoading}
                                            disabled={exportLoading}
                                            style={{
                                                margin: 0
                                            }}
                                        />
                                    </Box>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <SearchField
                                    value={deviceSearch}
                                    callback={(value) => $deviceTableDataStore.setSearch(value)}
                                    callbackTimeout={700}
                                    style={{
                                        width: 300
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <StyledCard>
                    <TableContainer
                        component={Paper}
                        style={{
                            border: "1px solid #5ac8fa",
                            margin: 0
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {tableColumns.map((item: any) => {
                                        if (!item.show) {
                                            return <></>;
                                        }
                                        return (
                                            <TableCell
                                                key={item.key}
                                                id={item.key}
                                                style={{
                                                    width: item.width,
                                                    maxWidth: item.width,
                                                    textAlign: item.align
                                                }}
                                                sortDirection={
                                                    deviceSort.sort.by === item.key
                                                        ? deviceSort.sort.order
                                                        : false
                                                }
                                            >
                                                <TableSortLabel
                                                    hideSortIcon={item.hideSortIcon}
                                                    direction={
                                                        deviceSort.sort.by === item.key
                                                            ? deviceSort.sort.order
                                                            : "asc"
                                                    }
                                                    onClick={() => {
                                                        if (!item.hideSortIcon) {
                                                            changeTableSort(item.key);
                                                        }
                                                    }}
                                                    active={deviceSort.sort.by === item.key}
                                                >
                                                    {item.label}
                                                </TableSortLabel>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loadingDeviceTable ? (
                                    <TableRow>
                                        <TableCell colSpan={10}>
                                            <CustomCircularProgress />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <DeviceTableLine />
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={$deviceTableDataStore.deviceData?.pagination?.total! ?? 0}
                            page={devicePage}
                            onPageChange={handleChangePage}
                            rowsPerPage={deviceRowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            style={{ display: "flex" }}
                        />
                    </TableContainer>
                    <Menu
                        disableScrollLock={true}
                        anchorEl={$anchorRefStore.data}
                        keepMounted
                        open={Boolean($anchorRefStore.data)}
                        onClose={() => {
                            $anchorRefStore.setAnchorRef(null);
                        }}
                    >
                        <MenuItem
                            onClick={(event) => {
                                $anchorRefStore.setAnchorRef(null);
                                $deviceTableDataStore.setEditDevice(true);
                            }}
                        >
                            Редактировать
                        </MenuItem>
                        {$deviceTableDataStore?.editDeviceData?.can_attach &&
                        $loginUserStore.isAdmin() ? (
                            <MenuItem
                                onClick={(event) => {
                                    $anchorRefStore.setAnchorRef(null);
                                    $membersDataStore.setAttach(true);
                                }}
                            >
                                Закрепить сотрудника
                            </MenuItem>
                        ) : null}
                        {$deviceTableDataStore?.editDeviceData?.can_detach &&
                        $loginUserStore.isAdmin() ? (
                            <MenuItem
                                onClick={(event) => {
                                    $anchorRefStore.setAnchorRef(null);
                                    $membersDataStore.setDetach(true);
                                }}
                            >
                                Открепить сотрудника
                            </MenuItem>
                        ) : null}
                    </Menu>
                    {($deviceTableDataStore.addNewDevice || $deviceTableDataStore.editDevice) && (
                        <AddDevice />
                    )}
                    {($membersDataStore.attach || $membersDataStore.detach) && <Attach />}
                </StyledCard>
            </>
        </MainContentWrap>
    );
});

export default DevicesList;
