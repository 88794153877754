import { makeAutoObservable } from "mobx";
import {
    DEFAULT_CONTRACT,
    DEFAULT_CONTRACT_ADDRESS,
    DEFAULT_CONTRACT_MEMBER,
    DEFAULT_ROWS_PER_PAGE,
} from '../utils/const';
import { OrderType } from "../types/order.sort";
import { ContractInfo, ContractList } from "../types/contractListInfo";
import {
    getContractDetail,
    getContractListInfo,
    getHouseListByContract,
    getMembersByContract,
    getCompletedWorkListByContract,
    getNormListByContract
} from "../api-actions/ContractsApi";
import throttle from "lodash.throttle";
import { contractAddress, contractAddressList } from "../types/contractAddress";
import { contractMember, contractMembersList } from "../types/contractMember";
import { CompletedWorkList } from "../types/completedWorks";
import { NormItemList } from "../types/normativeData";
import { formatDate } from "../utils/utils";

class ContractsStore {
    contractDetail?: ContractInfo | undefined;
    contractsData?: ContractList;
    contractsSearch: string = "";
    contractsPage: number = 0;
    contractsRowsPerPage: number = DEFAULT_ROWS_PER_PAGE;
    contractsloadingTable: boolean = true;
    contractsSort: OrderType = {
        sort: {
            by: "id",
            order: "asc"
        }
    };
    openEditContract: boolean = false;
    houseList: contractAddressList | undefined;

    contractAddressPage: number = 0;
    contractAddressRowsPerPage: number = DEFAULT_ROWS_PER_PAGE;
    contractAddressloadingTable: boolean = true;
    contractAddressSort: OrderType = {
        sort: {
            by: "address",
            order: "asc"
        }
    };
    contractAddressSearch: string = "";

    contractMembersList: contractMembersList | undefined;
    contractMembersPage: number = 0;
    contractMembersRowsPerPage: number = DEFAULT_ROWS_PER_PAGE;
    contractMembersLoadingTable: boolean = true;
    contractMembersSort: OrderType = {
        sort: {
            by: "guid",
            order: "asc"
        }
    };
    contractMembersSearch: string = "";
    currentCompletedWorkGuid: string = "";

    completedWorkList: CompletedWorkList | undefined;
    completedWorkPage: number = 0;
    completedWorkRowsPerPage: number = DEFAULT_ROWS_PER_PAGE;
    completedWorkLoadingTable: boolean = false;
    completedWorkSort: OrderType = {
        sort: {
            by: "guid",
            order: "asc"
        }
    };
    completedWorkSearch: string = "";

    currentNormGuid: string = "";
    normList: NormItemList | undefined;
    normPage: number = 0;
    normRowsPerPage: number = DEFAULT_ROWS_PER_PAGE;
    normLoadingTable: boolean = false;
    normSort: OrderType = {
        sort: {
            by: "guid",
            order: "asc"
        }
    };
    normSearch: string = "";

    addNewHouse: boolean = false;
    addNewUser: boolean = false;
    editCompletedWork: boolean = false;
    editNorm: boolean = false;

    addressEdit: boolean = false;
    addressEditData: contractAddress = DEFAULT_CONTRACT_ADDRESS;

    memberEdit: boolean = false;
    memberEditData: contractMember = DEFAULT_CONTRACT_MEMBER;

    editContractData: ContractInfo = DEFAULT_CONTRACT;

    constructor() {
        makeAutoObservable(this);
    }

    setPage(page: number) {
        this.contractsPage = page;
    }

    setRowsPerPage(rowsPerPage: number) {
        this.contractsRowsPerPage = rowsPerPage;
    }

    setCompletedWorkLoading(isLoading: boolean) {
        this.completedWorkLoadingTable = isLoading;
    }

    setNormLoading(isLoading: boolean) {
        this.normLoadingTable = isLoading;
    }

    setLoading(isLoading: boolean) {
        this.contractsloadingTable = isLoading;
    }

    setSort(sort: OrderType) {
        this.contractsSort = sort;
    }

    setData(data: ContractList) {
        this.contractsData = data;
    }

    setDetail(data: ContractInfo | undefined) {
        this.contractDetail = data;
    }

    setAddNewHouse(status: boolean) {
        this.addNewHouse = status;
    }

    setAddNewUser(status: boolean) {
        this.addNewUser = status;
    }

    setEditContractData(data: ContractInfo) {
        this.editContractData = data;
    }

    setEditCompletedWork(status: boolean) {
        this.editCompletedWork = status;
    }

    setEditNorm(status: boolean) {
        this.editNorm = status;
    }

    setOpenEditContract(status: boolean) {
        this.openEditContract = status;
        if (status === false) {
            this.setEditContractData(DEFAULT_CONTRACT);
        }
    }

    setHouseList(data: contractAddressList | undefined) {
        this.houseList = data;
    }

    setMembersList(data: contractMembersList | undefined) {
        this.contractMembersList = data;
    }

    setCompletedWorkList(data: CompletedWorkList | undefined) {
        if (data) {
            data.items.sort((a, b) => {
                // Преобразуем строки с датами в timestamp
                return new Date(b.start).getTime() - new Date(a.start).getTime();
            });
        }
        this.completedWorkList = data;
    }

    setNormList(data: NormItemList | undefined) {
        if (data) {
            data.items.sort((a, b) => {
                // Преобразуем строки с датами в timestamp
                return new Date(b.start).getTime() - new Date(a.start).getTime();
            });
        }

        this.normList = data;
    }

    getData(page: string, orderBy: string, direction: string, pageSize: string, search: string) {
        this.setLoading(true);
        const getContractList = async () => {
            return await getContractListInfo(page, orderBy, direction, pageSize, search);
        };
        const throttledGetContractList = throttle(getContractList, 100);
        throttledGetContractList()?.then((res) => {
            if (res) {
                this.setData(res);
            }
            this.setLoading(false);
        });
    }

    getDetail(guid: string) {
        this.setDetail(undefined);
        this.setHouseList(undefined);
        this.setMembersList(undefined);

        const getContractDetails = async () => {
            return await getContractDetail(guid);
        };
        const throttledGetContractDetails = throttle(getContractDetails, 10);
        throttledGetContractDetails()?.then((res) => {
            if (res) {
                this.setDetail(res);
            }
        });
    }

    getHouseList(
        contract: string,
        page: string,
        orderBy: string,
        direction: string,
        pageSize: string,
        search: string
    ) {
        this.setHouseList(undefined);
        this.setAddressLoading(true);
        const getHouses = async () => {
            return await getHouseListByContract(
                contract,
                page,
                orderBy,
                direction,
                pageSize,
                search
            );
        };
        const throttledGetHouseList = throttle(getHouses, 10);
        throttledGetHouseList()?.then((res) => {
            if (res) {
                this.setHouseList(res);
                this.setAddressLoading(false);
            }
        });
    }

    getMembersList(
        contract: string,
        page: string,
        orderBy: string,
        direction: string,
        pageSize: string,
        search: string
    ) {
        this.setMembersList(undefined);
        this.setMembersLoading(true);
        const getMembers = async () => {
            return await getMembersByContract(contract, page, orderBy, direction, pageSize, search);
        };
        const throttledGetMembersList = throttle(getMembers, 10);
        throttledGetMembersList()?.then((res) => {
            if (res) {
                this.setMembersList(res);
                this.setMembersLoading(false);
            }
        });
    }

    getCompletedWorkList(
        contract: string,
        page: string,
        orderBy: string,
        direction: string,
        pageSize: string,
        search: string
    ) {
        this.setCompletedWorkList(undefined);
        this.setCompletedWorkLoading(true);
        const getWorks = async () => {
            return await getCompletedWorkListByContract(
                contract,
                page,
                orderBy,
                direction,
                pageSize,
                search
            );
        };
        const throttledWorkList = throttle(getWorks, 10);
        throttledWorkList()?.then((res) => {
            if (res) {
                this.setCompletedWorkList(res);
                this.setCompletedWorkLoading(false);
            }
        });
    }

    getNormList(
        contract: string,
        page: string,
        orderBy: string,
        direction: string,
        pageSize: string,
        search: string
    ) {
        this.setNormList(undefined);
        this.setNormLoading(true);
        const getNorms = async () => {
            return await getNormListByContract(
                contract,
                page,
                orderBy,
                direction,
                pageSize,
                search
            );
        };
        const throttledNormList = throttle(getNorms, 10);
        throttledNormList()?.then((res) => {
            if (res) {
                this.setNormList(res);
                this.setNormLoading(false);
            }
        });
    }

    setAddressPage(page: number) {
        this.contractAddressPage = page;
    }

    setAddressRowsPerPage(rowsPerPage: number) {
        this.contractAddressRowsPerPage = rowsPerPage;
    }

    setAddressLoading(isLoading: boolean) {
        this.contractAddressloadingTable = isLoading;
    }

    setAddressSort(sort: OrderType) {
        this.contractAddressSort = sort;
    }

    setAddressEdit(status: boolean) {
        this.addressEdit = status;
    }

    setAddressEditData(data: contractAddress) {
        this.addressEditData = data;
    }

    setMemberEdit(status: boolean) {
        this.memberEdit = status;
    }

    setMemberEditData(data: contractMember) {
        this.memberEditData = data;
    }

    setMembersPage(page: number) {
        this.contractMembersPage = page;
    }

    setMembersRowsPerPage(rowsPerPage: number) {
        this.contractMembersRowsPerPage = rowsPerPage;
    }

    setCompletedWorkPage(page: number) {
        this.completedWorkPage = page;
    }

    setCompletedWorkRowsPerPage(rowsPerPage: number) {
        this.completedWorkRowsPerPage = rowsPerPage;
    }

    setNormPage(page: number) {
        this.normPage = page;
    }

    setNormRowsPerPage(rowsPerPage: number) {
        this.normRowsPerPage = rowsPerPage;
    }

    setMembersLoading(isLoading: boolean) {
        this.contractMembersLoadingTable = isLoading;
    }

    setMembersSort(sort: OrderType) {
        this.contractMembersSort = sort;
    }

    setCompletedWorkSort(sort: OrderType) {
        this.completedWorkSort = sort;
    }

    setNormSort(sort: OrderType) {
        this.normSort = sort;
    }

    setSearch(data: string) {
        this.contractsPage = 0 
        this.contractsSearch = data;
    }

    setAddressSearch(data: string) {
        this.contractAddressSearch = data;
        this.setAddressPage(0);
    }

    setMembersSearch(data: string) {
        this.contractMembersSearch = data;
        this.setMembersPage(0);
    }

    setCompletedWorkSearch(data: string) {
        this.completedWorkSearch = data;
        this.setCompletedWorkPage(0);
    }

    setNormSearch(data: string) {
        this.normSearch = data;
        this.setNormPage(0);
    }

    getName(data: ContractInfo | undefined) {
        if (data === undefined && this.contractDetail !== undefined) {
            data = this.contractDetail;
        }
        return (
            (data?.number ? data?.number : "") +
            (data?.date ? " от " + formatDate(data?.date, "d.m.y") : "")
        );
    }

    setCurrentCompletedWorkGuid(guid: string) {
        this.currentCompletedWorkGuid = guid;
    }

    setCurrentNormGuid(guid: string) {
        this.currentNormGuid = guid;
    }
}

export const $contractsDataStore = new ContractsStore();
