import throttle from 'lodash.throttle';
import { makeAutoObservable } from 'mobx';
import { getHouseListInfo } from '../api-actions/api-actions';
import { HouseList } from '../types/houseListInfo';
import { DEFAULT_ROWS_PER_PAGE } from '../utils/const';

class HouseListData {
    data: HouseList = {
        pagination: {
            per_page: DEFAULT_ROWS_PER_PAGE,
            total: 0,
            last_page: 0,
            to: 0,
            current_page: 0,
            from: 0,
        },
        items: [],
    };
    houseSearch: string = "";

    constructor() {
        makeAutoObservable(this);
    }

    setData(data: HouseList) {
        this.data = data;
    }

    setSearch(str: string) {
        this.houseSearch = str;
    }

    getData(
        houseSearch: string
    ) {
        const getHouseList = async () => {
            return await getHouseListInfo(houseSearch);
        };
        const throttledGetHouseList = throttle(getHouseList, 100);
        throttledGetHouseList()?.then((res) => {
            if (res) {
                this.setData(res);
            }
        });
    }
}

export const $houseListStore = new HouseListData();
