import { CircularProgress } from "@mui/material";

export function CustomCircularProgress() {
    return (
        <CircularProgress
            size={50}
            style={{
                position: "relative",
                top: "calc(50% - 25px)",
                left: "calc(50% - 25px)",
            }}
        />
    );
}
