import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

const TooltipComponent = styled("span")({
    width: 17,
    height: 17,
    display: "inline-block",
    borderRadius: "50%",
    lineHeight: "1.1rem",
    textAlign: "center",
    background: "#f9f9f9",
    border: "1px solid #eaeaea",
    transition: "box-shadow 0.08s ease",
    color: "#bbb",
    marginLeft: "0.3rem",
    cursor: "default",
    fontFamily: ['"ProximaNova"', "Arial", "sans-serif"].join(","),

    "&:hover": {
        boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.14)",
        border: "1px solid #e0e0e0",
        color: "#afafaf",
    },
});
type CustomTooltipProp = {
    tooltip: string;
};
export default function CustomTooltip({ tooltip }: CustomTooltipProp) {
    return (
        <Tooltip
            title={tooltip}
            componentsProps={{
                tooltip: {
                    style: {
                        backgroundColor: "#20a0ff",
                        fontFamily: [
                            '"ProximaNova"',
                            "Arial",
                            "sans-serif",
                        ].join(","),
                        color: "#fff",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "18px",
                        letterSpacing: 0.25,
                        padding: "8px 10px",
                        maxWidth: 300,
                        wordWrap: "break-word",
                        borderRadius: "7px",
                    },
                },
                arrow: {
                    style: {
                        color: "#20a0ff",
                    },
                },
            }}
            placement="right"
            arrow
        >
            <TooltipComponent>?</TooltipComponent>
        </Tooltip>
    );
}
