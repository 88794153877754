import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import {
    Link,
    NavLink,
} from 'react-router-dom';
import logo from './icons/logo.png';
import { $loginUserStore } from '../store/ResponseData';
import { $zoneTableDataStore } from '../store/ZoneTableStore';
import { AppRoute } from '../utils/const';
import { mockMenu } from '../utils/utils';

const StyledMenu = styled(Box)({
    background: "white",
    padding: 0,
    boxShadow: "5px 0px 10px -3px rgba(0, 0, 0, 0.2)",
    position: 'fixed',
    height: 75,
    width: '100%',
    maxWidth: '100vw',
    zIndex: 999,
});

const StyledNavLink = styled(NavLink)({
    color: "#20a0ff",
});

const MobileMenu = observer(() => {
    const handleClick = useCallback((id: number, name: string) => {
        $zoneTableDataStore.setShowZoneDetailes(false);
    }, []);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <StyledMenu>
            <Grid
                container
                mt={0}
                direction={"row"}
                justifyContent={"space-around"}
                alignItems={"center"}
                flexWrap={"nowrap"}
                sx={{
                    height: '75px',
                }}
            >
                <Grid item>
                    <div>
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleClickMenu}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="long-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            slotProps={{
                                paper: {
                                    style: {
                                        width: '50vw',
                                        zIndex: 9999,
                                    },
                                },
                            }}
                        >
                            {mockMenu.map((mockMenu, index) => (
                                <MenuItem key={index} onClick={handleClose}>
                                    <StyledNavLink
                                        to={mockMenu.url}
                                        onClick={() => handleClick(mockMenu.id, mockMenu.name)}
                                    >
                                        {mockMenu.name}
                                    </StyledNavLink>
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                </Grid>
                <Grid item>
                    <Link to={AppRoute.Root}>
                        <img src={logo} alt="ОКОЛО"/>
                    </Link>
                </Grid>
                <Grid item style={{textAlign: 'right'}}>
                    <p style={{
                        margin: 0,
                        fontSize: '12px',
                        fontWeight: 'bold',
                    }}>
                        [{$loginUserStore.data?.id}] {$loginUserStore.data?.name}
                    </p>
                    {
                        $loginUserStore.data?.roles.items.length
                            ? (<p style={{margin: 0, fontSize: "10px"}}>
                                {'Роль: ' + $loginUserStore.data?.roles.items.map((role) => role.name).join(", ")}
                            </p>)
                            : null
                    }
                    <Link
                        to={AppRoute.Root}
                        onClick={() => {
                            $loginUserStore.logout();
                        }}
                        style={{
                            margin: '5px 0 0',
                            fontSize: '12px',
                        }}
                    >
                        Выйти
                    </Link>
                </Grid>
            </Grid>
        </StyledMenu>
    );
});

export default MobileMenu;
