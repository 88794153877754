import styled from "@emotion/styled";
import { Card, Divider, Grid, IconButton } from "@mui/material";
import { observer } from "mobx-react";
import { $loginUserStore } from "../store/ResponseData";
import { AppRoute } from "../utils/const";

const StyledCard = styled(Card)({
    width: "250px",
    height: "155px",
    position: "absolute",
    left: "15px",
    top: "145px",
    overflowY: "unset",
});

const AvatarClickCard = observer(() => {
    return (
        <StyledCard>
            <Grid
                container
                direction={"column"}
                spacing={2}
                style={{
                    marginTop: "1%",
                    marginBottom: "1%",
                    zIndex: 999999,
                    position: 'absolute',
                    background: 'white',
                }}
            >
                <Grid item>
                    <Grid
                        container
                        direction={"row"}
                        spacing={2}
                        alignItems="center"
                        justifyContent={"flex-start"}
                    >
                        <Grid item xs={3}>
                            <div
                                style={{
                                    display: "flex",
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #5ac8fa",
                                    borderRadius: "50%",
                                    marginLeft: "7px",
                                }}
                            >
                                <p
                                    style={{
                                        textAlign: "center",
                                        margin: "auto",
                                    }}
                                >
                                    {$loginUserStore.data?.name
                                        .split(" ")
                                        .map(function (item) {
                                            return item[0];
                                        })
                                        ?.join("")
                                        .toUpperCase()}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={9}>
                            {$loginUserStore.data?.name}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Divider orientation="horizontal" flexItem />
                </Grid>
                <Grid item style={{ paddingTop: 0 }}>
                    <Grid
                        container
                        direction={"row"}
                        spacing={2}
                        alignItems="center"
                        style={{
                            marginTop: "1px",
                            cursor: "pointer",
                        }}
                        justifyContent={"flex-start"}
                        onClick={() => {
                            $loginUserStore.logout();
                        }}
                    >
                        <Grid
                            item
                            sx={{
                                display: "flex",
                                justifyContent: "center",

                                "& .MuiButtonBase-root": {
                                    transition:
                                        "background-color, opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                    opacity: ".5",
                                    "&:hover": {
                                        opacity: "1",
                                    },
                                },
                                "& svg": {
                                    "& path": {
                                        stroke: "#20a0ff",
                                        strokeWidth: "1.5",
                                        strokeLinecap: "round",
                                        strokeLinejoin: "round",
                                        strokeMiterlimit: "10",
                                    },
                                },
                            }}
                        >
                            <IconButton
                                color="primary"
                                aria-label="logout"
                                size="medium"
                                style={{ padding: 12 }}
                            >
                                <svg
                                    width="24"
                                    height="25"
                                    viewBox="0 0 24 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M17.4399 15.12L19.9999 12.56L17.4399 10" />
                                    <path d="M9.76001 12.5601H19.93" />
                                    <path d="M11.76 20.5C7.34001 20.5 3.76001 17.5 3.76001 12.5C3.76001 7.5 7.34001 4.5 11.76 4.5" />
                                </svg>
                            </IconButton>
                        </Grid>
                        <Grid item>Выйти</Grid>
                    </Grid>
                </Grid>
            </Grid>
        </StyledCard>
    );
});
export default AvatarClickCard;
