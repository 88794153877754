import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
    IconButton,
    TableCell,
    TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { $anchorRefStore } from '../../../../../store/AnchorRef';
import { $contractsDataStore } from '../../../../../store/ContractsStore';
import { $loginUserStore } from '../../../../../store/ResponseData';
import { CompletedWork } from '../../../../../types/completedWorks';
import { CustomCircularProgress } from '../../../../ui/CustomCircularProgress';

const CompletedWorksTableLine = observer(() => {
    const {
        completedWorkList,
        completedWorkRowsPerPage,
        completedWorkPage,
        contractDetail,
        completedWorkLoadingTable
    } = $contractsDataStore;

    if (contractDetail === undefined) {
        return <></>;
    }

    const counter = completedWorkList?.items?.filter((item) => {
        return item.contract === contractDetail?.guid;
    });

    if (completedWorkLoadingTable || counter === undefined) {
        return (
            <TableRow>
                <TableCell colSpan={4}>
                    <CustomCircularProgress />
                </TableCell>
            </TableRow>
        );
    }

    if (counter.length <= 0) {
        return (
            <TableRow>
                <TableCell colSpan={4} align="center">
                    Нет актов выполненных работ
                </TableCell>
            </TableRow>
        );
    }

    return (
        <>
            {completedWorkList?.items?.map((tableLine: CompletedWork, index) => {
                return (
                    <TableRow key={tableLine.guid} component={"tr"}>
                        <TableCell style={{ textWrap: "nowrap" }}>
                            {completedWorkRowsPerPage * completedWorkPage + index + 1}
                        </TableCell>
                        <TableCell style={{ textWrap: "nowrap" }}>
                            {dayjs(tableLine.start).format("DD.MM.YYYY") +
                                " - " +
                                dayjs(tableLine.end).format("DD.MM.YYYY")}
                        </TableCell>
                        <TableCell style={{ textWrap: "nowrap" }}>
                            {tableLine.planned_cost}
                        </TableCell>
                        {$loginUserStore.isAdmin() ? (
                            <TableCell>
                                <IconButton
                                    onClick={(event) => {
                                        $anchorRefStore.setAnchorRef(event.currentTarget);
                                        $contractsDataStore.setCurrentCompletedWorkGuid(tableLine?.guid ?? '');
                                    }}
                                >
                                    <MoreHorizIcon />
                                </IconButton>
                            </TableCell>
                        ) : null}
                    </TableRow>
                );
            })}
        </>
    );
});

export default CompletedWorksTableLine;
