import CloseIcon from '@mui/icons-material/Close';
import {
    Autocomplete,
    Dialog,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ButtonSquare } from '@synapse/frontend-react';
import { observer } from 'mobx-react';
import {
    useCallback,
    useEffect,
    useState,
} from 'react';
import InputMask from 'react-input-mask';
import {
    checkImei,
    newDeviceCreate,
} from '../../../api-actions/DevicesApi';
import { $deviceTableDataStore } from '../../../store/DeviceTableStore';
import { $loginUserStore } from '../../../store/ResponseData';
import { Device } from '../../../types/device';
import Notification from '../../ui/Notification';
import CustomTooltip from '../../ui/Tooltip';

const StyledAutocomplete = styled(Autocomplete)({
    width: "100%",
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "0.5px",
    fontSize: "16px",
    color: "#1d1d1b",
    textWrap: "nowrap",

    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#5ac8fa",
            borderRadius: "12px"
        },
        "&:hover fieldset": {
            borderColor: "#20a0ff"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#20a0ff"
        }
    },

    "& .MuiFormHelperText-root": {
        margin: "3px 0 0"
    }
});

const StyledDialog = styled(Dialog)({
    height: "auto",
    padding: "15px",
    margin: "0 auto"
});

const StyledTextField = styled(TextField)({
    marginBottom: "19px",
    borderRadius: "15px",
    background: "#fff",
    ".MuiOutlinedInput-notchedOutline": {
        border: "1px solid #5ac8fa",
        borderRadius: "15px"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#5ac8fa"
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#5ac8fa",
            borderRadius: "12px"
        },
        "&:hover fieldset": {
            borderColor: "#20a0ff"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#20a0ff"
        }
    },

    "& .MuiFormHelperText-root": {
        margin: "3px 0 0"
    }
});

const AddDevice = observer(() => {
    const isEdit = $deviceTableDataStore.editDevice;
    const [form, setForm] = useState<Device>($deviceTableDataStore.editDeviceData);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [checkingImei, setCheckingImei] = useState(false);

    const setFormValue = useCallback(
        (name: string, value: string) => {
            setForm({ ...form, [name]: value });

            if (value !== "" && errors.hasOwnProperty(name)) {
                setErrors({
                    ...errors,
                    [name]: ""
                });
            }
        },
        [form, errors]
    );

    const checkImeiExists = async () => {
        await checkImei(form.imei).then((response) => {
            if (response === "success") {
                setErrors({
                    ...errors,
                    imei: "Данный идентификатор уже существует"
                });
            }
        });
    };

    useEffect(() => {
        if ($deviceTableDataStore.editDeviceData.imei !== form.imei && form.imei.length > 0) {
            setCheckingImei(true);
            checkImeiExists();
            setCheckingImei(false);
        }
    }, [form.imei]);

    const modelList: string[] = ["Wan Way S20 (2G)", "Wan Way S20 (4G)", "Мовирег АТ-5800"];

    const handleSubmit = async () => {
        let newErrors: Record<string, string> = {};
        if (form.name === "") {
            newErrors.name = 'Поле "Название устройства" обязательно для заполнения';
        }
        if (form.imei === "") {
            newErrors.imei = 'Поле "Идентификатор устройства (IMEI)" обязательно для заполнения';
        }
        if (form.model === "") {
            newErrors.model = 'Поле "Модель" обязательно для заполнения';
        }
        const phoneRegex = /^\+7\(\d{3}\)\d{3}-\d{2}-\d{2}$/;

        if (form.phone && !phoneRegex.test(form.phone)) {
            newErrors.phone = 'Поле "Телефон" заполнено некорректно';
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length <= 0) {
            $deviceTableDataStore.setLoadingDeviceTable(true);
            await newDeviceCreate(form).then((resp) => {
                if (typeof resp === "string") {
                    setErrors({
                        ...errors,
                        imei: resp
                    });
                    return;
                }

                $deviceTableDataStore.updateData();
                $deviceTableDataStore.setAddNewDevice(false);
                $deviceTableDataStore.setEditDevice(false);
                if (isEdit) {
                    Notification({
                        title: "Устройство обновлено",
                        type: 'success',
                        duration: 30000
                    });
                } else {
                    Notification({
                        title: "Устройство зарегистрировано",
                        message:
                            "Для закрепления за устройством сотрудника воспользуйтесь функционалом «Закрепление»",
                        type: 'success',
                        duration: 30000
                    });
                }
            });
            $deviceTableDataStore.setLoadingDeviceTable(false);
        }
    };

    const PhoneMaskedTextField = useCallback((props: any) => {
        return (
            <InputMask mask="+7(999)999-99-99" maskChar='' {...props} style={{ width: "100%" }}>
                {(inputProps: any) => (
                    <StyledTextField
                        placeholder="№ телефона устройства"
                        label="№ телефона устройства"
                        name="phone"
                        id="phone"
                        {...inputProps}
                        disabled={props.disabled}
                        error={props.errors?.length > 0}
                        helperText={props.errors?.length > 0 ? props.errors : false}
                    />
                )}
            </InputMask>
        );
    }, []);

    return (
        <StyledDialog
            open={$deviceTableDataStore.addNewDevice || $deviceTableDataStore.editDevice}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 20%)",
                    width: 692,
                    overflowY: "unset"
                }
            }}
            keepMounted={true}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            maxWidth={false}
            scroll="body"
        >
            <IconButton
                aria-label="close"
                style={{
                    position: "absolute",
                    right: "-45px",
                    top: "-45px",
                    color: "white"
                }}
                onClick={() => {
                    $deviceTableDataStore.setAddNewDevice(false);
                    $deviceTableDataStore.setEditDevice(false);
                }}
            >
                <CloseIcon fontSize="large" />
            </IconButton>

            <Grid container justifyContent={"center"}>
                <Grid item xs={9}>
                    <Grid container direction={"column"} justifyContent={"center"} spacing={2}>
                        <Grid item xs={7}>
                            <DialogTitle
                                style={{
                                    textAlign: "center"
                                }}
                            >
                                <Typography
                                    variant={"h1"}
                                    style={{
                                        fontSize: "34px",
                                        lineHeight: "36px",
                                        letterSpacing: "0",
                                        fontWeight: "600",
                                        color: "#20a0ff",
                                        textWrap: "nowrap"
                                    }}
                                    component={"span"}
                                >
                                    {isEdit
                                        ? "Редактирование устройства"
                                        : "Регистрация нового устройства"}
                                </Typography>
                            </DialogTitle>
                        </Grid>
                        <form
                            noValidate
                            style={{
                                paddingLeft: "18px",
                                maxWidth: "100%"
                            }}
                        >
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={2}
                                    justifyContent={"space-between"}
                                >
                                    <Grid item xs={11}>
                                        <StyledTextField
                                            placeholder="Название устройства"
                                            label="Название устройства"
                                            required
                                            size="medium"
                                            variant="outlined"
                                            fullWidth
                                            name="name"
                                            id="name"
                                            value={form.name}
                                            onChange={(value) =>
                                                setFormValue("name", value.target.value)
                                            }
                                            error={errors.name?.length > 0}
                                            helperText={
                                                errors.name?.length > 0 ? errors.name : false
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <CustomTooltip tooltip={""} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={2}
                                    justifyContent={"space-between"}
                                >
                                    <Grid item xs={11}>
                                        <StyledTextField
                                            disabled={!$loginUserStore.isAdmin() || checkingImei}
                                            placeholder="Идентификатор устройства (IMEI)"
                                            label="Идентификатор устройства (IMEI)"
                                            required
                                            size="medium"
                                            variant="outlined"
                                            fullWidth
                                            name="imei"
                                            id="imei"
                                            value={form.imei}
                                            onChange={(value) =>
                                                setFormValue("imei", value.target.value)
                                            }
                                            error={errors.imei?.length > 0}
                                            helperText={
                                                errors.imei?.length > 0 ? errors.imei : false
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <CustomTooltip tooltip={""} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: "19px" }}>
                                <Grid
                                    container
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={2}
                                    justifyContent={"space-between"}
                                >
                                    <Grid item xs={11}>
                                        <StyledAutocomplete
                                            disabled={!$loginUserStore.isAdmin()}
                                            noOptionsText={"Модель не найдена"}
                                            id="model"
                                            value={form.model}
                                            options={modelList}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Модель"
                                                    label="Модель"
                                                    required
                                                    error={errors.model?.length > 0}
                                                    helperText={
                                                        errors.model?.length > 0
                                                            ? errors.model
                                                            : false
                                                    }
                                                />
                                            )}
                                            onChange={(evt: any) => {
                                                setFormValue("model", evt.target.textContent);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <CustomTooltip tooltip={""} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={11}>
                                <PhoneMaskedTextField
                                    disabled={!$loginUserStore.isAdmin()}
                                    variant="outlined"
                                    onChange={(evt: any) => setFormValue("phone", evt.target.value)}
                                    value={form.phone}
                                    errors={errors.phone}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={2}
                                    justifyContent={"space-between"}
                                >
                                    <Grid item xs={11}>
                                        <StyledTextField
                                            disabled={!$loginUserStore.isAdmin()}
                                            placeholder="Описание"
                                            label="Описание"
                                            size="medium"
                                            variant="outlined"
                                            fullWidth
                                            name="description"
                                            id="description"
                                            value={form.description}
                                            onChange={(evt: any) =>
                                                setFormValue("description", evt.target.value)
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                        <Grid item xs={12} style={{ paddingTop: 0, paddingLeft: 0 }}>
                            <ButtonSquare
                                disabled={Object.values(errors).some((value) => value !== "")}
                                variant="contained"
                                color="primary"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    margin: "15px auto"
                                }}
                                onClick={handleSubmit}
                            >
                                {isEdit ? "Обновить" : "Зарегистрировать"}
                            </ButtonSquare>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </StyledDialog>
    );
});
export default AddDevice;
