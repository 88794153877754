import L from 'leaflet';
import { observer } from 'mobx-react';
import 'leaflet-arrowheads';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import {
    currentCustomIcon,
    greenCustomIcon,
} from '../../ui/map/CustomMarker';
import { $monitoringStore } from '../../../store/MonitoringStore';
import { MarkerInfo } from '../../../types/monitoring';

const MonitoringMap = observer(() => {
    const map = useMap();

    map.attributionControl.setPrefix('');
    map.zoomControl.setPosition('bottomleft');

    map.on('popupclose', function(e){
        $monitoringStore.setCurrentMarker(null);
    });

    const {
        mapData,
        filters,
        updateBounds,
        currentMarker,
    } = $monitoringStore;

    useEffect(() => {
        $monitoringStore.getFilterInfo(filters);
    }, [filters]);

    useEffect(() => {
        if (currentMarker instanceof L.Marker) {
            const position = currentMarker.getLatLng();

            map.flyTo(
                [
                    position.lat,
                    position.lng + (0.5/111)
                ],
                16
            );
            currentMarker.openPopup();
        }
    }, [map, currentMarker]);

    useEffect(() => {
        if (mapData) {
            L.geoJSON().clearLayers();

            map.eachLayer((layer) => {
                if (layer instanceof L.Marker) {
                    layer.remove();
                } else if (layer instanceof L.Polygon) {
                    layer.remove();
                } else if (layer instanceof L.Path) {
                    layer.remove();
                } else if (layer instanceof L.Polyline) {
                    layer.remove();
                } else if (layer instanceof L.Marker) {
                    layer.remove();
                }
            });

            const lats: number[] = [];
            const lngs: number[] = [];
            const markersForTable: MarkerInfo[] = [];

            L.geoJson(mapData, {
                onEachFeature: function (feature, layer) {
                    if (layer instanceof L.Polyline && feature.properties.type === "line") {
                        L.polyline((feature.geometry as any).coordinates)
                            .arrowheads({
                                size: '5m',
                                // fill: true
                            })
                            .bindPopup(
                                (feature.properties.date !== ""
                                    ? "Время определения: " + feature.properties.date + "<br/>"
                                    : "") +
                                    (feature.properties.name !== ""
                                        ? "Сотрудник: " + feature.properties.name + "<br/>"
                                        : ""),
                                {
                                    maxWidth: 400,
                                    offset: new L.Point(-10, 10)
                                }
                            ).addTo(map);
                    } else if (layer instanceof L.Polyline && feature.properties.type === "zone") {
                        L.polygon(
                            (feature.geometry as any).coordinates.reverse(),
                            {
                                opacity: 0.6,
                            }
                        )
                            .bindTooltip(
                                feature.properties.name,
                                {
                                    direction: 'center',
                                }
                            )
                            .addTo(map);
                    } else if (feature.properties.type === "point") {
                        if (feature.properties.is_latest) {
                            const curIcon = (() => {
                                switch (feature.properties.status) {
                                    case 'green':
                                        return greenCustomIcon;
                                    default:
                                        return currentCustomIcon;
                                }
                            })();

                            const marker = L.marker(
                                (feature.geometry as any).coordinates,
                                {
                                    icon: curIcon
                                }
                            ).bindPopup(
                                (feature.properties.date !== ""
                                    ? "Время определения: " + feature.properties.date + "<br/>"
                                    : "") +
                                    (feature.properties.name !== ""
                                        ? "Сотрудник: " + feature.properties.name + "<br/>"
                                        : ""),
                                {
                                    maxWidth: 400,
                                    offset: new L.Point(-5, -30)
                                }
                            );

                            if (feature.properties.user !== '') {
                                markersForTable.push({
                                    id: feature.properties.user,
                                    marker: marker,
                                })
                            }

                            marker.addTo(map);
                        }

                        lats.push((feature.geometry as any).coordinates[1]);
                        lngs.push((feature.geometry as any).coordinates[0]);
                    }
                }
            });

            $monitoringStore.setMarkers(markersForTable);

            if (updateBounds && lats.length && lngs.length) {
                const bounds = L.latLngBounds(
                    L.latLng(
                        Math.min.apply(null, lngs),
                        Math.min.apply(null, lats)
                    ),
                    L.latLng(
                        Math.max.apply(null, lngs),
                        Math.max.apply(null, lats)
                    )
                );
                map.fitBounds(bounds);
            }
        }
    }, [map, mapData, updateBounds]);

    return null;
});

export default MonitoringMap;
