import { makeAutoObservable } from "mobx";

class MenuStore {
    menuStatus: boolean = localStorage.getItem("menuStatus")
        ? localStorage.getItem("menuStatus") === 'true'
        : true;

    constructor() {
        makeAutoObservable(this);
    }

    setMenuStatus(status: boolean) {
        this.menuStatus = status;
        localStorage.setItem("menuStatus", JSON.stringify(status));
    }
}

export const $mainMenuStore = new MenuStore();
