import { makeAutoObservable } from "mobx";
import { DEFAULT_POINT, DEFAULT_ROWS_PER_PAGE } from "../utils/const";
import { OrderType } from "../types/order.sort";
import { Feature, GeoJsonProperties, Geometry, Point as GeoJsonPoint } from "geojson";
import { Zone, ZoneList } from "../types/zoneList";
import { getZoneDetail } from "../components/pages/zone/ZoneListApi";
import throttle from "lodash.throttle";

class ZoneTableStore {
    page: number = 0;
    rowsPerPage: number = DEFAULT_ROWS_PER_PAGE;
    loadingZoneTable: boolean = true;
    sort: OrderType = {
        sort: { by: "guid", order: "desc" }
    };
    newZoneCoordinates?: GeoJsonPoint | undefined;
    tableLinedata?: ZoneList;
    showZoneDetailes: boolean = false;
    selectedZoneData: Zone = DEFAULT_POINT;
    returnInitCoordinates?: boolean = false;
    zoneCoordinates: Feature<Geometry, GeoJsonProperties> | undefined;
    zoneSearch: string = "";

    constructor() {
        makeAutoObservable(this);
    }

    setPage(page: number) {
        this.page = page;
    }

    setRowsPerPage(rowsPerPage: number) {
        this.rowsPerPage = rowsPerPage;
    }

    setLoadingZoneTable(isLoading: boolean) {
        this.loadingZoneTable = isLoading;
    }

    setSort(sort: OrderType) {
        this.sort = sort;
    }

    setNewCoordinates(data: GeoJsonPoint | undefined) {
        this.newZoneCoordinates = data;
    }

    setTableLineData(data: ZoneList) {
        this.tableLinedata = data;
    }

    setShowZoneDetailes(status: boolean) {
        this.showZoneDetailes = status;
    }

    setSelectedZoneData(data: Zone) {
        this.selectedZoneData = data;
    }

    setCoordinates(newCo: Feature<Geometry, GeoJsonProperties> | undefined) {
        this.zoneCoordinates = newCo;
    }

    setReturnInitCoordinates(v: boolean) {
        this.returnInitCoordinates = v;
    }

    setSearch(data: string) {
        this.page = 0 
        this.zoneSearch = data;
    }

    getDetail(guid: string) {
        const getZoneDetails = async () => {
            return await getZoneDetail(guid);
        };
        const throttledGetZoneDetails = throttle(getZoneDetails, 100);
        throttledGetZoneDetails()?.then((res) => {
            if (res) {
                this.setSelectedZoneData(res);
            }
        });
    }
}

export const $zoneTableDataStore = new ZoneTableStore();
