import axios from '../axios';
import Notification from '../components/ui/Notification';
import {
    Members,
    RoleList,
} from '../types/member';

export async function getMembers(
    page: string,
    orderBy: string,
    direction: string,
    pageSize: string,
    search?: string,
    organisation?: string
) {
    let data = {
        page: page,
        orderBy: orderBy,
        direction: direction,
        pageSize: pageSize,
        search: search,
        organisation: organisation
    };

    let config = {
        method: "post",
        url: "/UserService/Users_list",
        data: data
    };

    try {
        const response = await axios.request(config);
        if (!response) return;
        const result: Members = response.data.data;

        if (!result) {
            return;
        }
        if (result.items.length === undefined) {
            return {
                ...result,
                items: []
            };
        }
        return result;
    } catch (error: any) {
        Notification({
            title: "Не удалось загрузить список сотрудников",
            type: 'error'
        });
    }
}

export async function saveUserRoles(roleInfo: { user: string; role: string }) {
    let data = roleInfo;

    let config = {
        method: "post",
        url: "/UserService/Users_saveUserRoles",
        data
    };

    try {
        const response = await axios.request(config);
        if (!response) return;
        const result: Members = response.data.data;

        if (!result) {
            return;
        }
        if (result.items?.length === undefined) {
            return {
                ...result,
                items: []
            };
        }
        return result;
    } catch (error: any) {
        Notification({
            title: "Ошибка при сохранении ролей",
            type: 'error'
        });
    }
}

export async function getRoleList() {
    let config = {
        method: "get",
        url: "/UserService/Users_roleList",
    };

    try {
        const response = await axios.request(config);
        if (!response) return;
        const result: RoleList = response.data.data;

        if (!result) {
            return;
        }
        if (result.items.length === undefined) {
            return [];
        }
        return result.items;
    } catch (error: any) {
        Notification({
            title: "Не удалось загрузить список ролей",
            type: 'error'
        });
    }
}

export async function saveAttachedUk(ukInfo: { user: string; uk: string }) {
    let data = ukInfo;

    let config = {
        method: "post",
        url: "/UserService/Users_saveAttachedUk",
        data
    };

    try {
        const response = await axios.request(config);
        if (!response) return;
        const result: Members = response.data.data;

        if (!result) {
            return;
        }
        if (result.items?.length === undefined) {
            return {
                ...result,
                items: []
            };
        }
        return result;
    } catch (error: any) {
        Notification({
            title: "Ошибка при сохранении УК",
            type: 'error'
        });
    }
}
