import { Point as GeoJsonPoint } from 'geojson';
import L from 'leaflet';
import { observer } from 'mobx-react';
import {
    useEffect,
    useState,
} from 'react';
import {
    GeoJSON,
    useMap,
} from 'react-leaflet';
import { useLocation } from 'react-router-dom';
import activeIconUrl from '../../icons/pin-active.svg';
import defaultIconUrl from '../../icons/pin-default.svg';
import greenIconUrl from '../../icons/pin-green.svg';
import redIconUrl from '../../icons/pin-red.svg';
import yellowIconUrl from '../../icons/pin-yellow.svg';
import { $loginUserStore } from '../../../store/ResponseData';
import { $zoneTableDataStore } from '../../../store/ZoneTableStore';
import {
    AppRoute,
    DEFAULT_ICONANCHOR,
    DEFAULT_ICONSIZE,
} from '../../../utils/const';

export const currentCustomIcon = L.icon({
    iconUrl: defaultIconUrl,
    iconSize: DEFAULT_ICONSIZE,
    iconAnchor: DEFAULT_ICONANCHOR
});

export const activeCustomIcon = L.icon({
    iconUrl: activeIconUrl,
    iconSize: DEFAULT_ICONSIZE,
    iconAnchor: DEFAULT_ICONANCHOR
});

export const greenCustomIcon = L.icon({
    iconUrl: greenIconUrl,
    iconSize: DEFAULT_ICONSIZE,
    iconAnchor: DEFAULT_ICONANCHOR
});

export const yellowCustomIcon = L.icon({
    iconUrl: yellowIconUrl,
    iconSize: DEFAULT_ICONSIZE,
    iconAnchor: DEFAULT_ICONANCHOR
});

export const redCustomIcon = L.icon({
    iconUrl: redIconUrl,
    iconSize: DEFAULT_ICONSIZE,
    iconAnchor: DEFAULT_ICONANCHOR
});

const CustomMarker = observer(() => {
    const [coordinates, setCoordinates] = useState<GeoJsonPoint>();
    const {
        newZoneCoordinates,
        selectedZoneData,
        returnInitCoordinates,
    } = $zoneTableDataStore;

    const map = useMap();
    let fg = L.featureGroup();
    const location = useLocation();

    map.attributionControl.setPrefix('');
    map.zoomControl.setPosition('bottomright');

    map.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
            layer.remove();
        }
        if (layer instanceof L.Polygon) {
            layer.remove();
        }
    });

    useEffect(() => {
        if (selectedZoneData) {
            setCoordinates(selectedZoneData.coordinates);
        }
        if (newZoneCoordinates) {
            setCoordinates(newZoneCoordinates);
        }
    }, [selectedZoneData, newZoneCoordinates]);

    // переключение кнопок toolbar
    // в зависимости от того, нарисована фигура на карте или нет - показываем разные кнопки
    const toggleDrawButton = (drawLayers: number) => {
        // Если это карта для быстрого просмотра фигуры в списке зон - toolbar не нужен
        if (location.pathname === AppRoute.ZoneList) {
            return;
        }

        if (!$loginUserStore.isAdmin()) {
            return;
        }

        const isEditMode = !!drawLayers;
        map.pm.Toolbar.setButtonDisabled("drawRectangle", isEditMode);
        map.pm.Toolbar.setButtonDisabled("drawPolygon", isEditMode);
        map.pm.addControls({
            dragMode: isEditMode,
            removalMode: isEditMode,
            editMode: isEditMode
        });
    };

    // добавляем слой фигуры на карту
    const addNewLayer = (layer: L.Layer) => {
        fg.addLayer(layer);
        const newBound = fg.toGeoJSON() as GeoJSON.FeatureCollection<GeoJSON.Geometry>;
        $zoneTableDataStore.setCoordinates(newBound.features[0]);
    };

    map.on("pm:create", (e) => {
        const layer = e.layer;
        addNewLayer(e.layer);

        const drawLayers = Object.keys(map.pm.getGeomanDrawLayers()).length; // количество слоев зон нарисованных Geoman
        toggleDrawButton(drawLayers);

        layer.on("pm:edit", (e) => {
            addNewLayer(e.layer);
        });
    });

    // Обработчик события удаления
    map.on("pm:remove", (e) => {
        const layer = e.layer;
        fg.removeLayer(layer);
        const newBound = fg.toGeoJSON() as GeoJSON.FeatureCollection<GeoJSON.Geometry>;
        $zoneTableDataStore.setCoordinates(newBound.features[0]);
        const drawLayers = Object.keys(map.pm.getGeomanDrawLayers()).length; // количество слоев зон нарисованных Geoman
        toggleDrawButton(drawLayers);
    });

    if (newZoneCoordinates || selectedZoneData) {
        if (selectedZoneData.coordinates && typeof selectedZoneData.coordinates === "string") {
            selectedZoneData.coordinates = JSON.parse(selectedZoneData.coordinates);
        }

        const geojsonObject = L.geoJSON(
            newZoneCoordinates ? coordinates : selectedZoneData.coordinates,
            {
                pointToLayer: (feature, latlng) => {
                    return L.marker(latlng, {
                        icon: currentCustomIcon
                    });
                }
            }
        );

        geojsonObject.addTo(map);

        if (geojsonObject.getBounds().getNorthEast() !== undefined) {
            map.fitBounds(geojsonObject.getBounds());
        }

        // Добавляем в слой для редактирования загруженную, ранее сохраненную в БД зону
        if (selectedZoneData.coordinates.type !== "Point") {
            geojsonObject.eachLayer((layer) => {
                addNewLayer(layer);
                // Прослушиваем событие редактирования слоя загруженной зоны
                layer.on("pm:edit", (e) => {
                    addNewLayer(e.layer);
                });
            });
        }

        if (returnInitCoordinates) {
            geojsonObject.eachLayer((layer) => {
                addNewLayer(layer);
            });
        }

        const drawLayers = Object.keys(fg.getLayers()).length; // количество слоев зон загруженных на карту
        toggleDrawButton(drawLayers);

        return <GeoJSON data={coordinates!} />;
    } else {
        return null;
    }
});

export default CustomMarker;
