import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from "@mui/material";
import { observer } from "mobx-react";
import { ChangeEvent, useCallback } from "react";
import { $zoneTableDataStore } from "../../../store/ZoneTableStore";
import { CustomCircularProgress } from "../../ui/CustomCircularProgress";
import ZoneTableLine from "./ZoneTableLine";

const ZoneTable = observer(() => {
    const tableColumns = [
        {
            key: "id",
            label: "ID",
            width: 328,
            hideSortIcon: false,
        },
        {
            key: "address",
            label: "Адрес",
            width: 350,
            hideSortIcon: false,
        },
        {
            key: "uk_id",
            label: "УК",
            width: 350,
            hideSortIcon: false,
        },
        {
            key: "description",
            label: "Описание",
            width: 221,
            hideSortIcon: true,
        },
    ];
    const {
        page,
        rowsPerPage,
        loadingZoneTable,
        sort,
    } = $zoneTableDataStore;

    const changeTableSort = useCallback(
        (key: string) => {
            $zoneTableDataStore.setSort({
                sort: {
                    by: key,
                    order:
                        sort.sort.by !== key
                            ? 'asc'
                            : sort.sort.order === 'asc' ? 'desc' : 'asc',
                },
            });
        },
        [sort]
    );

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => {
        $zoneTableDataStore.setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        $zoneTableDataStore.setRowsPerPage(parseInt(event.target.value, 10));
        $zoneTableDataStore.setPage(0);
    };

    return (
        <TableContainer
            component={Paper}
            style={{
                border: "1px solid #5ac8fa",
                margin: 0,
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        {tableColumns.map((item: any) => {
                            return (
                                <TableCell
                                    key={item.key}
                                    id={item.key}
                                    sortDirection={
                                        sort.sort.by === item.key
                                            ? sort.sort.order
                                            : false
                                    }
                                    style={{
                                        width: item.width,
                                        maxWidth: item.width,
                                    }}
                                >
                                    <TableSortLabel
                                        active={sort.sort.by === item.key}
                                        hideSortIcon={item.hideSortIcon}
                                        direction={
                                            sort.sort.by === item.key
                                                ? sort.sort.order
                                                : "asc"
                                        }
                                        onClick={() => {
                                            if (!item.hideSortIcon) {
                                                changeTableSort(item.key);
                                            }
                                        }}
                                    >
                                        {item.label}
                                    </TableSortLabel>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        loadingZoneTable
                        ? (<TableRow>
                            <TableCell
                                colSpan={6}
                            >
                                <CustomCircularProgress />
                            </TableCell>
                        </TableRow>)
                        : <ZoneTableLine />
                    }
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={$zoneTableDataStore.tableLinedata?.pagination?.total! ?? 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                style={{ display: "flex" }}
            />
        </TableContainer>
    );
});
export default ZoneTable;
